<template>
  <main :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
    <div class="exchange-service-common">
      <!-- TITLE SERVICE -->
      <section class="intro-service container">
        <!-- INFO SERVICE -->
        <h3 class="sp title-name">Gポイント</h3>
        <div class="title-left">
          <img src="../../../assets/images/g-point.png" alt="logo">
          <div class="info">
            <h3 class="pc title-name">Gポイント</h3>
            <p class="mb-2"><span>◆「Ｇポイント」とは</span><br>
              Ｇポイントは、約120社の中からお好きなサービスに交換利用できるポイントサービスです。<br>
              Ｇポイントギフトから受取手続きを行っていただくことで、Ｇポイントのお受け取りが可能です。<br>
              <br>
              ・Ｇポイント交換先一覧は<a @click.prevent="openUrl('https://pmall.gpoint.co.jp/shopsearch/?penservice=gtop')" class="text-green">こちら</a><br>
              ・Ｇポイントの会員登録は<a @click.prevent="openUrl('https://www.gpoint.co.jp/scripts/register/RegistUserBasicEntry.do?siteid=81191376&serviceid=R10000100978')" class="text-green">こちら</a><br>
              ※Ｇポイントご利用の際、Ｇポイントへの会員登録が必要となります。
            </p>
          </div>
        </div>

        <!-- RULES SERVICE -->
        <ul class="rules-service">
          <li>
            <span>交換日数</span>
            <p><b>{{ pointExchangeService.exchange_date }}</b></p>
          </li>
          <li>
            <span>交換手数料</span>
            <p><b>無料</b></p>
          </li>
          <li>
            <span>交換レート</span>
            <strong class="unit-gpoint"><div>1pt →</div><span>Ｇポイント<br>(Ｇポイントギフト)</span></strong>
          </li>
          <li>
            <span>最低交換ポイント</span>
            <p class="point">500pt</p>
          </li>
        </ul>
      </section>

      <!-- NOTE SERVICE -->
      <section class="note-service d-flex">
        <div class="container d-inline-block">
          <h4>◆Ｇポイントの受取方法について</h4>
          <p>ChibiJobポイントから交換申請後、即時でＧポイントギフトへ交換されます。<br>
            またはChibiJobポイントのマイページ内「ポイント交換履歴」にＧポイントギフトが表示されます。<br>
            「ポイントを受取りに行く」ボタンをクリックし、以下に記載の[Ｇポイントの受取方法]をご確認の上、有効期限内にＧポイントをお受け取りください。</p>
            <br>
          <p>※Ｇポイントギフトは「ポイントを受取りに行く」ボタンからＧポイントサイトへ遷移して<br>
            受取手続きを行っていただくことでＧポイントとして反映されます。<br>
          お手続きが未完了な状態では、Ｇポイントは反映されませんのでご注意ください。</p>
          <br>
          <p>━━━━━━━━━━━━━━<br>
          Ｇポイントの受取方法<br>
          ━━━━━━━━━━━━━━</p>
          <br>
          <p class="text-indent-large">（1）ChibiJobポイントから交換申請後、「ポイントを受取りに行く」ボタンをクリックすると<br>Ｇポイントサイトへ遷移します。<br>
            または<br>
            ChibiJobポイントのマイページ内「ポイント交換履歴」にて、交換済みの交換先サービス名：Ｇポイントを選択。<br>
            ※1<br>
          「ポイントを受取りに行く」ボタンをクリックするとＧポイントサイトへ遷移します。</p>
          <p class="text-indent-large">（2）Ｇポイントのログイン画面が表示されますので、Ｇポイントでご利用のID・パスワードを入力のうえ、<br>
          「ログインしてポイントをもらう」ボタンを押すとＧポイントの受取は完了です。※2</p>
          <p class="text-indent-large">（3）ポイントは即時で加算されますので、Ｇポイントサイト内、ポイント通帳ページの[ショップ・サービス]タブにてご確認ください。<br>
          ▼Ｇポイント通帳<br>
          <a @click.prevent="openUrl('https://www.gpoint.co.jp/scripts/direct/userinfo/MMPmallPointBook.do')">https://www.gpoint.co.jp/scripts/direct/userinfo/MMPmallPointBook.do</a><br>
            <br>
          ※1<br>
          フィーチャーフォンでのご利用の場合、画面表示がうまく行われません。<br>
          その場合は、以下Ｇポイント推奨環境ページをご確認いただき、<br>推奨環境となる端末にて、ＧポイントギフトのURLを入力しご利用ください。<br>
          ▼Ｇポイントの推奨環境<br>
          <a @click.prevent="openUrl('https://www.gpoint.co.jp/support/systemreqs.html')">https://www.gpoint.co.jp/support/systemreqs.html</a><br>
            Ｇポイントの推奨環境外からご利用の場合は、<br>ＧポイントギフトによるＧポイント受取ができない場合がございます。<br>
            <br>
            ※2<br>
            ＧポイントIDをお持ちでない方は、「会員登録（無料）」にて会員登録を行ってください。<br>
          </p>
          <h4>■注意事項</h4>
           <p class="text-indent">・Ｇポイントギフトの有効期限は、ChibiJobポイントのマイページ内「ポイント交換履歴」にてご確認ください。</p>
           <p class="text-indent">・Ｇポイントギフトは、受取になられた本人の利用のみ有効です。</p>
           <p class="text-indent">・他者への譲渡、売買は禁止いたします。</p>
           <p class="text-indent">・Ｇポイントギフトを第三者に知られないように大切に保管してください。</p>
           <p class="text-indent">・Ｇポイントギフト情報の漏洩、紛失、破損に対しては、その責任を負いません。</p>
           <p class="text-indent">・Ｇポイントギフトのお取り替え、再発行はいたしません。</p>
           <p class="text-indent">・Ｇポイントギフトを日本国外から利用する場合の不具合については一切責任を負いません。</p>
            <br>
          <h4>◆Ｇポイントに関するお問い合わせ</h4>
          <p>Ｇポイントに関するお問い合わせは以下ページよりお問い合わせください。<br>
            お問い合わせの際には、必ず問い合わせ内容とともに「お問い合わせ番号」をご連絡ください。<br>
            お問合せ番号は、ChibiJobのマイページ内「ポイント交換履歴」にてご確認ください。<br>
            <a @click.prevent="openUrl('https://support.gpoint.co.jp/hc/ja/requests/new')">https://support.gpoint.co.jp/hc/ja/requests/new</a><br>
            ……………………………………………
          </p>
        </div>
      </section>

      <!-- EXCHANGE POINT -->
      <div class="control-exchange">
        <!-- BTN EXCHANGE POINT -->
        <button @click="handleBeforeExchangePoint"
                v-if="profile && token"
                class="btn-exchange-point">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを交換する
        </button>

        <!-- BTN redirect to login -->
        <button v-else @click="openPageNative('Login')"
                class="btn-exchange-point btn-redirect">
          すでに会員の方はこちらからログイン
        </button>

        <!-- BTN BACK -->
        <div class="link-back">
          <router-link class="btn-back"
                       to="/exchange-point">
            <span>
               <ChevronLeftIcon size="20" />ポイント交換先一覧へ戻る
            </span>
          </router-link>
        </div>
      </div>

      <!-- LIST QA -->
<!--      <div class="wrap-list-QA">-->
<!--        <QuestionAnswer class="list-QA container"-->
<!--                        :list-question-answer="EXCHANGE_POINT_QA"/>-->
<!--      </div>-->
    </div>

    <!-- MODAL INPUT EXCHANGE POINT -->
    <Modal @close-modal="flagModalInput = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalInput">
      <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="validateBeforeSubmit()"
        slot="body"
        class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;Gポイント</h3>
        <p class="sub-title">※Ｇポイントギフトへの交換となります。<br>
          ポイント交換いただくには、別途お手続きが必要です。
        </p>

        <!-- CONTENT -->
        <div class="point-exchange">
          <span>交換</span>
          <InputField v-model="pointExchange"
                      field="交換ポイント"
                      type="text"
                      class="point-type"
                      :max-length="15"
                      @input="handleNumber($event)"
                      @focusout="handleNumberFocusOut()"
                      @focusin="handleNumberFocusIn($event)"
                      :rules="{
                        required: true,
                        number_exchange: true,
                        compare_point: {
                          first: pointExchange && parseInt(pointExchange.replace(/,/g, '')),
                          second: user_point.total_point_amount
                        },
                        range_point: true
                       }"
                      vid="point"/>
          <span class="pc">pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange money-ref">
          <span>付与</span>
          <InputField v-model="pointExchange"
                      class="point-type"
                      :disabled="true"/>
          <span class="pc">Ｇポイント<br>（Ｇポイントギフト）</span>
        </div>

        <!-- NOTE -->
        <p class="note">※Ｇポイントギフトは受取手続きを行っていただくことでＧポイントとして反映されます。<br>
          ※有効期限内に受取手続きを実施ください。</p>
      </ValidationObserver>

      <div class="btn-footer"
           slot="footer">
        <button @click.prevent="validateBeforeSubmit">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換ポイントを確定する
        </button>
      </div>
    </Modal>

    <!-- MODAL CONFIRM EXCHANGE POINT -->
    <Modal @close-modal="flagModalConfirm = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalConfirm">
      <div slot="body"
           class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;Gポイント</h3>
        <p class="sub-title">※Ｇポイントギフトへの交換となります。<br>
          ポイント交換いただくには、別途お手続きが必要です。
        </p>

        <!-- CONTENT -->
        <div class="point-exchange confirm"
             :class="{ 'point-input' : !confirm }">
          <span>交換</span>
          <strong class="point">{{ pointExchange }}</strong>
          <span>pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img v-if="confirm" class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange confirm">
          <span :class="{ 'orange': !confirm }">付与</span>
          <strong>{{ pointExchange }}</strong>
          <span :class="{ 'orange': !confirm }">Ｇポイント<br>（Ｇポイントギフト）</span>
        </div>

        <!-- NOTE MODAL CONFIRM -->
        <p class="note">※Ｇポイントギフトは受取手続きを行っていただくことでＧポイントとして反映されます。<br>
          ※有効期限内に受取手続きを実施ください。</p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button @click="handleExchangePoint"
                :disabled="!confirm"
                :class="{'disabled': !confirm}">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">{{ confirm ? '交換申請する' : '交換申請が完了しました' }}
        </button>
      </div>
    </Modal>

    <!-- MODAL COMPLETE EXCHANGE POINT -->
    <Modal @close-modal="handleCloseModal"
           class="modal-center modal-exchange-point"
           v-if="flagModalComplete">
      <div class="content-body"
           slot="body">
        <h3>ポイント交換先<br class="sp">&#12288;Gポイント</h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>{{ dataComplete.point }}<span class="color-orange sp" v-html="'Ｇポイント<br>（Ｇポイントギフト）'"></span></strong>
            <time
              class="time-request"
              v-if="isExchangeAutoSuccess"
            >
              交換承認日：
              <span>
                {{ dataComplete.request_date | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange unit-gpoint"
                v-html="'Ｇポイント<br>（Ｇポイントギフト）'"></span>
        </div>

        <div class="voucher" v-if="dataComplete.code">
          <span class="font-weight-bold">Ｇポイントお問合せ番号</span>
          <p class="mb-2">{{ dataComplete.code }}</p>
          <time class="font-weight-bold">Ｇポイントギフト有効期限：<span>{{ dataComplete.expiration_datetime | formatDate('YYYY年MM月DD日') }}</span></time>
        </div>

        <!-- NOTE MODAL GPOINT -->
        <p
          class="note"
          v-if="dataComplete.code"
        >
          ※「ポイントを受取りに行く」ボタンからＧポイントサイトへ遷移しＧポイントをお受け取りください。<br>
          ※お受け取りいただけない場合は、
          <a
            class="link-orange"
            href="https://support.gpoint.co.jp/hc/ja/requests/new?ticket_form_id=4416570678297"
            target="_blank"
          >
            Ｇポイントのお問い合わせフォーム
          </a>より上記「Ｇポイントお問合せ番号」を記載のうえお問い合わせください。
        </p>
        <p
          class="text-instruct manual"
          v-if="!isExchangeAutoSuccess"
        >
          ポイント交換の申請を受け付けました。<br>承認されましたら、マイページのポイント交換履歴から、ギフトの受け取りができるようになります。
        </p>
      </div>
      <div
        class="btn-footer"
        slot="footer"
        v-if="isExchangeAutoSuccess"
      >
        <!-- LINK TO GIFT PAD -->
        <button @click.prevent="openUrl(linkGPoint + dataComplete.code_crypt)"
                class="btn-common">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを受取りに行く
        </button>
      </div>
    </Modal>

    <!-- MODAL NOTIFY -->
    <Modal @close-modal="turnOffNoti"
           class="modal-center modal-exchange-error"
           v-if="flagModalNoti">
      <div slot="body">
        <p v-html="messageNotification"/>

        <!-- button in modal ostiaries_error -->
        <button v-if="this.$route.meta.ostiaries_error"
                @click.prevent="handleReturn"
                v-html="(this.$route.meta.ostiaries_error === 'true') ? '番号入力画面に戻る' : '完了'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- MODAL CAUTION INFO -->
    <Modal @close-modal="flagModalRedirect = false"
           class="modal-center modal-notify"
           v-if="flagModalRedirect">
      <div slot="body">
        <p>ポイント交換前に必要事項をご入力ください。</p>

        <!-- button in modal ostiaries_error -->
        <button
                @click.prevent="handelRedirectUpdateInfo"
                v-html="'会員情報入力'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- MODAL VERIFY AUTHEN MOBILE -->
    <ModalVerifyAuthenMobile @close-modal="flagModalVerifyAuth = false"
                              v-if="flagModalVerifyAuth"/>

    <!-- MODAL NOTIFY LINE_PAY AUTHENTICATION-->
    <!-- <ModalLinePayNotification
      :turnOffLinePayNotification="turnOffLinePayNotification"
      :isLinePayValidationError="isLinePayValidationError"
      :linePayValidationErrorMessage="linePayValidationErrorMessage"/> -->
  </main>
</template>

<script>
// import QuestionAnswer from '@/components/QuestionAnswer'
// import { EXCHANGE_POINT_QA } from '@/enum/question-answer'
import { ChevronLeftIcon } from 'vue-feather-icons'
import Modal from '@/components/advertising-detail/Modal'
import InputField from '@/components/form/InputField'
import { mapActions } from 'vuex'
import Common from '@/mixins/common.mixin'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'
import SNS from '@/mixins/sns.mixin'
import store from '@/store'

export default {
  name: 'Index',

  mixins: [Common, SNS],

  components: {
    ModalVerifyAuthenMobile,
    InputField,
    Modal,
    // QuestionAnswer,
    ChevronLeftIcon
  },

  data () {
    return {
      confirm: true,
      linkGPoint: process.env.VUE_APP_LINK_GPOINT
    }
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})

      if (this.token) {
        store.dispatch('auth/userPoint')
      }
    }
  },

  methods: {
    ...mapActions('exchange', ['userExchangeGpoint']),

    async handleExchangePoint () {
      this.onExchangePoint(this.userExchangeGpoint)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/exchange-service.scss';

.exchange-service-common .note-service {
  padding: 60px 15px 115px;
  @media #{$info-phone} {
    padding: 60px 15px 100px;
  }

  & > div {
    @media #{$info-phone} {
      padding: 0;
      font-size: 14px;
    }
  }

  .text-indent {
    padding-left: 17px;
    @media #{$info-phone} {
      padding-left: 15px;
    }

    &-large {
      margin-left: 1rem;
      text-indent: -2.4rem;
      padding-left: 9px;
      margin-bottom: 20px;
      @media #{$info-phone} {
        margin: auto auto 20px;
        padding-left: 25px;
      }
    }
  }
}

.modal-exchange-point .content-body {
  .note {
    text-align: left;
  }

  span {
    font-size: 18px;
  }

  .point-exchange {
    &.money-ref {
      transform: translateX(80px);
      display: flex;
      align-items: center;
      @media #{$info-phone} {
        align-items: flex-start;
        transform: translateX(0);
      }

      .point-type:after {
        @media #{$info-phone} {
          content: "Ｇポイント \A （Ｇポイントギフト）";
          white-space: pre-wrap;
          top: 8px;
          right: 5px;
        }
      }

      span {
        margin: 0 10px;
      }
    }

    &.confirm {
      align-items: center;

      span {
        margin: 0 10px;
        font-size: 12px;
      }

      &.point-input {
        @media #{$info-phone} {
          display: none;
        }
      }
    }
  }
}
</style>
